import { Link } from "react-router-dom";
import './App.css';

function App() {
  return (
    <div className="App">
      <h1>Yay! Successfully Working!</h1>
      <div>
        <Link to="/first">First</Link> |
        <Link to="/second">Second</Link> 
      </div>
    </div>
  );
}

export default App;
